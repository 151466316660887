import React from "react"
import PropTypes from "prop-types"
import cx from 'classnames';

import Shape2 from "../../assets/images/shape/shape2.png"
import Shape3 from "../../assets/images/shape/shape3.png"
import Shape5 from "../../assets/images/shape/shape5.png"
import Shape6 from "../../assets/images/shape/shape6.png"
import Shape7 from "../../assets/images/shape/shape7.png"
import Shape8 from "../../assets/images/shape/shape8.png"

const defaultLinesConfig = {
  left: true,
  middle: true,
  right: true
}

const PageBanner = ({
                      pageTitle,
  variant,
                      showShapes = true,
                      compact = false,
  showLines = true,
  subheading = null,
  linesConfig = defaultLinesConfig
}) => {
  return (
    <div className={cx('page-title-area', { compact: compact })}>
      <div className="container">
        <div className={cx('page-title-content', { compact: compact })}>
          <h1>{pageTitle}</h1>
        </div>
        {subheading || <h5 className="pt-3 tac" style={{ color: 'white' }}>{variant?.banner?.subheading}</h5>}
      </div>
      {showShapes && (
        <>
          <div className="shape2"><img src={Shape2} alt="Shape" /></div>
          <div className="shape3"><img src={Shape3} alt="Shape" /></div>
          <div className="shape5"><img src={Shape5} alt="Shape" /></div>
          <div className="shape6"><img src={Shape6} alt="Shape" /></div>
          <div className="shape7"><img src={Shape7} alt="Shape" /></div>
          <div className="shape8"><img src={Shape8} alt="Shape" /></div>
        </>
      )}

      {showLines & (
        <div className="lines">
          {linesConfig.left && <div className="line"></div>}
          {linesConfig.right && <div className="line"></div>}
          {linesConfig.middle && <div className="line"></div>}
        </div>
      )}
    </div>
  )
}

PageBanner.propTypes = {
  showShapes: PropTypes.bool,
  showLines: PropTypes.bool,
  pageTitle: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  linesConfig: PropTypes.object,
}

export default PageBanner